import classnames from "classnames";
import { FC, RefObject, useCallback, useContext, useMemo, useState } from "react";
import { HrefBehavior, TranslationScopes } from "@finbackoffice/enums";
import { MINUTE_IN_MS, formatAppNameText, CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import {
    AuthContext,
    CasinoFavoritesContext,
    GtagEvents,
    ModalsContext,
    RuntimeConfigContext,
    formatNumber,
    useGtag,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { IReelResponse } from "@finbackoffice/clientbff-client";
import Link from "next/link";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import { getCasinoGameImgSrc } from "utils/helpers";
import { CasinoToolsContext } from "contexts";
import Translate from "components/base/translate/Translate";
import { ReelsContext } from "contexts";
import { ModalTypes, RouteNames } from "utils/constants";
import ReelsGameItem from "./ReelsGameItem";
import styles from "./reels-game-item.module.sass";

type IProps = {
    game: IReelResponse;
    reelsGameModalRef: RefObject<IModalForwardRefProps>;
};

const ReelsGameModal: FC<IProps> = ({ game, reelsGameModalRef }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { fireEvent } = useGtag();
    const runtimeConfig = useContext(RuntimeConfigContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { createCasinoFavorite, deleteCasinoFavorite, favoriteGamesState } =
        useContext(CasinoFavoritesContext);
    const { handlePlayNow } = useContext(CasinoToolsContext);
    const { toggleReelLiked, seenReels, setActiveGame } = useContext(ReelsContext);
    const [loading, setLoading] = useState(false);
    const { setCurrentModal, loginModalRef } = useContext(ModalsContext);

    const [likesAmount, setLikesAmount] = useState(
        Math.floor((Date.now() - new Date(game.created_at).valueOf()) / MINUTE_IN_MS) +
            game.total_likes,
    );

    const gameMedia = game.translations[0];
    const isLink = [HrefBehavior.External, HrefBehavior.Relative].includes(game.href_behavior);
    const isGame = [HrefBehavior.GameSlot, HrefBehavior.GameLiveCasino].includes(
        game.href_behavior,
    );
    const isSlots = game.href_behavior === HrefBehavior.GameSlot;
    const gameId = isGame && gameMedia.href ? `${gameMedia.href}-mobile` : "";
    const thumbnailSrc = useMemo(() => {
        if (isGame && gameId) {
            const gameType =
                game.href_behavior === HrefBehavior.GameSlot
                    ? CasinoGameTypeEnum.VIDEO_SLOTS
                    : CasinoGameTypeEnum.LIVE_CASINO;
            return getCasinoGameImgSrc(runtimeConfig, gameId, gameType, []);
        } else if (isLink) {
            return `${ASSETS_URL}/${formatAppNameText(
                COMMON_SITE_CONFIGS.appName,
            )}/common/favicon.ico`;
        }
        return "";
    }, [
        ASSETS_URL,
        COMMON_SITE_CONFIGS.appName,
        game.href_behavior,
        gameId,
        isGame,
        isLink,
        runtimeConfig,
    ]);
    const isFavorite = useMemo(
        () => !!favoriteGamesState.games.find((favGame) => favGame.game_id === gameId) ?? false,
        [favoriteGamesState.games, gameId],
    );

    const sharesAmount = useMemo(() => {
        return Math.floor(likesAmount / 15);
    }, [likesAmount]);

    const handleFavoriteClick = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            if (isFavorite) {
                await deleteCasinoFavorite(gameId);
            } else {
                await createCasinoFavorite(gameId);
            }
            setLoading(false);
        }
    }, [createCasinoFavorite, deleteCasinoFavorite, gameId, isFavorite, loading]);

    const handleShare = async () => {
        const url = `/${RouteNames.CASINO}/${RouteNames.REEL}/${game.id}`;
        if (navigator.share !== undefined) {
            try {
                await navigator.share({
                    title: `${gameMedia.title} | ${gameMedia.label}`,
                    url,
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            navigator.clipboard.writeText(url);
        }
        fireEvent(GtagEvents.ReelsShare, {
            reels_title: gameMedia.title,
            reels_label: gameMedia.label || "",
        });
    };

    const handleLike = async () => {
        if (isUserLoggedIn) {
            if (isLiked) {
                setLikesAmount((state) => --state);
            } else {
                setLikesAmount((state) => ++state);
            }
            toggleReelLiked(game.id);
        } else {
            loginModalRef.current?.open();
        }
    };

    const isLiked = useMemo(
        () =>
            seenReels.find((reel) => reel.id === game.id && reel.is_liked) || game.is_liked_by_user,
        [game.id, game.is_liked_by_user, seenReels],
    );

    const onPlayButtonClick = useCallback(() => {
        setCurrentModal(null);
        handlePlayNow(gameId, isSlots);
        fireEvent(GtagEvents.ReelsModalPlay, {
            reels_title: gameMedia.title,
            reels_label: gameMedia.label || "",
        });
    }, [
        fireEvent,
        gameId,
        gameMedia.label,
        gameMedia.title,
        handlePlayNow,
        isSlots,
        setCurrentModal,
    ]);

    const renderButton = useMemo(() => {
        if (isLink && gameMedia.href) {
            if (game.href_behavior === HrefBehavior.Relative) {
                return (
                    <Link
                        href={gameMedia.href}
                        className={styles.reelsModalBtn}
                        onClick={() => {
                            setCurrentModal(null);
                        }}>
                        Go
                    </Link>
                );
            } else if (game.href_behavior === HrefBehavior.External) {
                return (
                    <a href={gameMedia.href} rel="noreferrer" className={styles.reelsModalBtn}>
                        Go
                    </a>
                );
            }
        } else if (isGame) {
            return (
                <Button
                    type="button"
                    variant="secondary"
                    className={styles.reelsModalBtn}
                    onClick={onPlayButtonClick}>
                    <Translate tid="casino_play_now" namespace={TranslationScopes.Casino} />
                </Button>
            );
        }
        return null;
    }, [game.href_behavior, gameMedia.href, isGame, isLink, onPlayButtonClick, setCurrentModal]);

    const renderLikesAmount: string = useMemo(() => formatNumber(likesAmount, 1), [likesAmount]);

    return (
        <Modal
            ref={reelsGameModalRef}
            type={ModalTypes.REELS_GAME}
            animateVariant="opacity"
            styleClass={styles.reelsModal}
            onClose={() => {
                setActiveGame(null);
            }}>
            <>
                <span className={styles.reelsModalTitle}>Reels</span>
                <div className={styles.reelsModalContent}>
                    <ReelsGameItem game={game} mediaVariant="full" />
                    <section className={styles.reelsModalInfo}>
                        <div className={styles.reelsModalInfoButtons}>
                            <Button
                                type="button"
                                onClick={handleLike}
                                className={classnames([
                                    {
                                        [styles.liked]: isLiked,
                                    },
                                ])}>
                                <Svg
                                    src="/common/mobile/base-icons/reels-like.svg"
                                    wrapper="span"
                                    className={styles.like}
                                />
                                {renderLikesAmount}
                            </Button>
                            <Button type="button" onClick={handleShare}>
                                <Svg
                                    src="/common/mobile/base-icons/reels-share.svg"
                                    wrapper="span"
                                    className={styles.crown}
                                />
                                {sharesAmount}
                            </Button>
                        </div>
                        <div className={styles.reelsModalInfoDiv}>
                            <span className={styles.bluredBg}>
                                <span style={{ background: game.color ?? "" }} />
                            </span>
                            <div>
                                <Img
                                    source={thumbnailSrc}
                                    alt={""}
                                    title={""}
                                    width={40}
                                    height={40}
                                />
                                <p>
                                    <span>{gameMedia.title}</span>
                                    <span>{gameMedia.label}</span>
                                </p>
                                {isUserLoggedIn && isGame && (
                                    <i
                                        className={classnames(
                                            styles.unstarred,
                                            isFavorite && styles.starred,
                                        )}
                                        onClick={handleFavoriteClick}
                                    />
                                )}
                            </div>
                            {gameMedia.text && <p>{gameMedia.text}</p>}
                        </div>
                    </section>
                </div>

                {renderButton}
            </>
        </Modal>
    );
};

export default ReelsGameModal;
