import classnames from "classnames";
import { IReelResponse } from "@finbackoffice/clientbff-client";
import {
    FC,
    RefObject,
    SyntheticEvent,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { useRouter } from "next/router";
import {
    GtagEvents,
    useDidUpdateEffect,
    useGtag,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import Img from "components/base/img/Img";
import { ReelsContext } from "contexts";
import Video from "components/base/video/Video";
import styles from "./reels-game-item.module.sass";

type IProps = {
    game: IReelResponse;
    mediaVariant: "short" | "full";
    pauseVideo?: boolean;
    isExpandedView?: boolean;
};

const ReelsGameItem: FC<IProps> = ({ game, mediaVariant, pauseVideo, isExpandedView }) => {
    const router = useRouter();
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { fireEvent } = useGtag();
    const { markReelsSeen, seenReels, activeGame, setActiveGame } = useContext(ReelsContext);

    const [hasErr, setHasErr] = useState(false);
    const gameMedia = game.translations[0];
    const gameMediaSrc = useMemo(() => {
        if (mediaVariant === "full") {
            return gameMedia.content_media?.url ?? "";
        }
        return gameMedia.preview_media?.url ?? "";
    }, [gameMedia.content_media?.url, gameMedia.preview_media?.url, mediaVariant]);
    const isVideo = gameMediaSrc?.includes("mp4");
    const isShort = mediaVariant === "short";
    const videoRef: RefObject<HTMLVideoElement> = useRef(null);

    useDidUpdateEffect(() => {
        if (!isExpandedView) {
            if (videoRef.current?.paused) {
                videoRef.current?.play();
            }
        }
    }, [isExpandedView]);

    const handleVideoError = useCallback(() => {
        setHasErr(true);
    }, []);

    const handleReelsGameClick = useCallback(() => {
        if (!activeGame) {
            setActiveGame(game);
            markReelsSeen(game.id);
            fireEvent(GtagEvents.ReelsModalOpen, {
                reels_title: game.translations[0].title,
                reels_label: game.translations[0].label || "",
            });
        }
    }, [activeGame, fireEvent, game, markReelsSeen, setActiveGame]);

    const handleTouchStart = useCallback(() => {
        if (!videoRef.current?.paused) {
            videoRef.current?.pause();
        }
    }, []);

    const handleTouchEnd = useCallback(() => {
        if (videoRef.current?.paused) {
            videoRef.current?.play();
        }
    }, []);

    const handleTimeUpdate = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            if (pauseVideo) {
                if (e.currentTarget.currentTime > 1) {
                    e.currentTarget.pause();
                }
            }
        },
        [pauseVideo],
    );

    const isNew = useMemo(
        () => seenReels.find((reel) => reel.id === game.id) === undefined,
        [game.id, seenReels],
    );

    if (hasErr) {
        return null;
    }

    return (
        <div className={classnames("casino-game-item", styles.gameItem)}>
            <div
                className={classnames([
                    {
                        [styles.new]: isShort && isNew && !isExpandedView,
                    },
                ])}
                onClick={handleReelsGameClick}>
                {isExpandedView && (
                    <span className={styles.titleExpandedView}>{gameMedia.title}</span>
                )}
                {isVideo ? (
                    <Video
                        ref={videoRef}
                        src={gameMediaSrc}
                        muted={isShort || !!router.query.reelId}
                        poster={`${ASSETS_URL}/common/mobile/base-icons/reels-preload.webp`}
                        onError={handleVideoError}
                        onTimeUpdate={isShort ? handleTimeUpdate : undefined}
                        onTouchStart={!isShort ? handleTouchStart : undefined}
                        onTouchEnd={!isShort ? handleTouchEnd : undefined}
                        loopWithDelay={isShort}
                    />
                ) : (
                    <Img
                        source={gameMediaSrc}
                        alt={gameMedia.label ?? ""}
                        title={gameMedia.label ?? ""}
                        width={190}
                        height={200}
                        // placeholder={`${ASSETS_URL}/common/mobile/base-icons/reels-preload.webp`}
                    />
                )}
            </div>
        </div>
    );
};

export default ReelsGameItem;
