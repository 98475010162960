import { SiteContentScopes, TranslationScopes } from "@finbackoffice/enums";
import { FC, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ModalsContext, useTranslation } from "@finbackoffice/site-core";
import { IReelResponse } from "@finbackoffice/clientbff-client";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { Chars } from "utils/strings";
import { ReelsContext, ReelsProvider } from "contexts";
import { IModalForwardRefProps } from "components/base/modal/Modal";
import CasinoWidgetContainer from "../casino-widget-container/CasinoWidgetContainer";
import ReelsGameItem from "./ReelsGameItem";
import styles from "./reels.module.sass";
import ReelsGameModal from "./ReelsGameModal";

const Widget: FC = () => {
    const { t } = useTranslation(TranslationScopes.Casino);
    const { games, loading, activeGame } = useContext(ReelsContext);
    const { clearCurrentModal } = useContext(ModalsContext);
    const [expanded, setExpanded] = useState(false);
    const gameModalRef: RefObject<IModalForwardRefProps> = useRef(null);

    const gamesListMemo = useMemo(() => {
        if (!expanded) {
            return games.filter((_game, index) => index <= 3);
        }
        return games;
    }, [expanded, games]);

    useEffect(() => {
        if (activeGame && !gameModalRef.current?.isOpened) {
            gameModalRef.current?.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeGame]);

    useEffect(
        () => () => {
            clearCurrentModal();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const headingTranslation = useMemo(() => t("casino_reel_thrills").trim(), [t]);
    const descriptionTranslation = useMemo(() => t("casino_reel_thrills_description").trim(), [t]);

    return (
        <CasinoWidgetContainer
            loading={loading}
            variant="reelThrills"
            expand={expanded}
            renderHeader={
                <>
                    <span>
                        {headingTranslation && <span>{headingTranslation}</span>}
                        {descriptionTranslation && <strong>{descriptionTranslation}</strong>}
                    </span>
                    {expanded && (
                        <Button
                            className={styles.close}
                            type="button"
                            onClick={() => setExpanded(false)}
                        />
                    )}
                    {games.length > 4 && !expanded && (
                        <Button
                            type="button"
                            className={styles.seeAllReels}
                            onClick={() => setExpanded(true)}>
                            <Translate tid="casino_see_all" namespace={TranslationScopes.Casino} />{" "}
                            {Chars.nbsp}
                            {`(${games.length})`}
                        </Button>
                    )}
                </>
            }>
            {gamesListMemo.map((game, index) => (
                <ReelsGameItem
                    key={game.id}
                    game={game}
                    mediaVariant="short"
                    isExpandedView={expanded}
                    pauseVideo={expanded ? index != undefined && !(index % 3 === 0) : false}
                />
            ))}
            {activeGame && <ReelsGameModal reelsGameModalRef={gameModalRef} game={activeGame} />}
        </CasinoWidgetContainer>
    );
};

type IProps = {
    scope: SiteContentScopes;
    defaultActiveReel?: IReelResponse;
};

const Reels: FC<IProps> = ({ scope, defaultActiveReel }) => {
    return (
        <ReelsProvider scope={scope} defaultActiveReel={defaultActiveReel}>
            <Widget />
        </ReelsProvider>
    );
};

export default Reels;
